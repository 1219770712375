import { Database } from "../../core/database";
import Dexie from "dexie";
import { CustomerEquipment, Customer, CustomerGroup, CustomerContract, CustomerContractType, CustomerEquipmentPlan, CustomerEquipmentPlanPriority, CustomerEquipmentPlanTask, CustomerAddress, CustomerEquipmentChangeLogTransaction, CustomerEquipmentOrderTransaction, CustomerContact } from "./models/customer";
import { Equipment, EquipmentType, Item, ItemQuantityUnit, SubItemAssignment, SparePart, ServiceItem, StepStatus, Stock, StockItem, EquipmentSparePartAssignment, EquipmentServiceItemAssignment, ItemGroup } from "./models/general";
import { Order, OrderType, OrderPriority, OrderSparePartAssignment, OrderStep, OrderServiceItemAssignment, OrderAssignment, CompletionCode, RejectionCode, DebitCode, OrderSignatureImage, CompletionCodeGroup } from "./models/order";
import { User } from "../../core/database/models/auth";
import { TechnicianMessage, TechnicianMessageRecipient, TechnicianMessageConfirmation } from "./models/messaging";
import { Location, LocationRegion, LocationDefaultServiceItem } from "./models/tracking";
import { DocumentationLink, DocumentationFile } from "./models/documentation";
import { MaintenanceAccount } from "./models/auth";
import { version } from "moment";
import { AttributeType } from "../../core/database/models/attribute";
import AppPlugin from "../../core/plugin/app";

export default class EuropressDatabase extends Database
{
    //#region Maintenance app: General
    Equipment: Dexie.Table<Equipment, number>;
    EquipmentSparePartAssignment: Dexie.Table<EquipmentSparePartAssignment, number>;
    EquipmentServiceItemAssignment: Dexie.Table<EquipmentServiceItemAssignment, number>;
    EquipmentType: Dexie.Table<EquipmentType, number>;
    Item: Dexie.Table<Item, number>;
    ItemGroup: Dexie.Table<ItemGroup, number>;
    ItemQuantityUnit: Dexie.Table<ItemQuantityUnit, number>;
    SubItemAssignment: Dexie.Table<SubItemAssignment, number>;
    StepStatus: Dexie.Table<StepStatus, number>;
    // Proxy to Item ( see tableAliases)
    SparePart: Dexie.Table<Item, number>;
    // Proxy to Item ( see tableAliases)
    ServiceItem: Dexie.Table<Item, number>;
    Stock: Dexie.Table<Stock, number>;
    StockItem: Dexie.Table<StockItem, number>;
    //#endregion

    //#region Maintenance app: Auth
    MaintenanceAccount: Dexie.Table<MaintenanceAccount, number>;
    //#endregion

    //#region Maintenance app: Customer
    Customer: Dexie.Table<Customer, number>;
    CustomerGroup: Dexie.Table<CustomerGroup, number>;
    CustomerEquipment: Dexie.Table<CustomerEquipment, number>;
    CustomerContract: Dexie.Table<CustomerContract, number>;
    CustomerContractType: Dexie.Table<CustomerContractType, number>;
    CustomerEquipmentPlan: Dexie.Table<CustomerEquipmentPlan, number>;
    CustomerEquipmentPlanPriority: Dexie.Table<CustomerEquipmentPlanPriority, number>;
    CustomerEquipmentPlanTask: Dexie.Table<CustomerEquipmentPlanTask, number>;
    CustomerAddress: Dexie.Table<CustomerAddress, number>;
    CustomerContact: Dexie.Table<CustomerContact, number>;
    CustomerEquipmentChangeLogTransaction: Dexie.Table<CustomerEquipmentChangeLogTransaction, number>;
    CustomerEquipmentOrderTransaction: Dexie.Table<CustomerEquipmentOrderTransaction, number>;
    //#endregion

    //#region Maintenance app: Order
    Order: Dexie.Table<Order, number>;
    OrderType: Dexie.Table<OrderType, number>;
    OrderPriority: Dexie.Table<OrderPriority, number>;
    OrderSparePartAssignment: Dexie.Table<OrderSparePartAssignment, number>;
    OrderServiceItemAssignment: Dexie.Table<OrderServiceItemAssignment, number>;
    OrderStep: Dexie.Table<OrderStep, number>;
    OrderAssignment: Dexie.Table<OrderAssignment, number>;
    OrderSignatureImage: Dexie.Table<OrderSignatureImage, number>;
    CompletionCode: Dexie.Table<CompletionCode, number>;
    CompletionCodeGroup: Dexie.Table<CompletionCodeGroup, number>;
    RejectionCode: Dexie.Table<RejectionCode, number>;
    DebitCode: Dexie.Table<DebitCode, number>;
    //#endregion

    //#region Maintenance app: Messaging
    TechnicianMessage: Dexie.Table<TechnicianMessage, number>;
    TechnicianMessageRecipient: Dexie.Table<TechnicianMessageRecipient, number>;
    TechnicianMessageConfirmation: Dexie.Table<TechnicianMessageConfirmation, number>;
    //#endregion

    //#region Maintenance app: Tracking
    Location: Dexie.Table<Location, number>;
    LocationRegion: Dexie.Table<LocationRegion, number>;
    LocationDefaultServiceItem: Dexie.Table<LocationDefaultServiceItem, number>;
    //#endregion

    //#region Maintenance app: Documentation
    DocumentationLink: Dexie.Table<DocumentationLink, number>;
    DocumentationFile: Dexie.Table<DocumentationFile, number>;
    //#endregion

    public get tableAliases(): {[key: string]: Dexie.Table<any, any>}
    {
        return {
            //#region Maintenance app: General
            MaintenanceEquipment: this.Equipment,
            MaintenanceEquipmentSparePartAssignment:  this.EquipmentSparePartAssignment,
            MaintenanceEquipmentServiceItemAssignment: this.EquipmentServiceItemAssignment,
            MaintenanceEquipmentType: this.EquipmentType,
            MaintenanceItem: this.Item,
            MaintenanceItemQuantityUnit: this.ItemQuantityUnit,
            MaintenanceSubItemAssignment: this.SubItemAssignment,
            MaintenanceServiceItem: this.Item,
            MaintenanceSparePart: this.Item,
            MaintenanceStepStatus: this.StepStatus,
            MaintenanceItemGroup: this.ItemGroup,
            SparePart: this.Item,
            ServiceItem: this.Item,
            ServiceItemQuantityUnit: this.ItemQuantityUnit,
            SparePartQuantityUnit: this.ItemQuantityUnit,
            MaintenanceStock: this.Stock,
            MaintenanceStockItem: this.StockItem,
            //#endregion

            //#region Maintenance app: Auth
            // MaintenanceAccount is already prefixed!
            //#endregion

            //#region Maintenance app: Customer
            MaintenanceCustomer: this.Customer,
            MaintenanceCustomerGroup: this.CustomerGroup,
            MaintenanceCustomerEquipment: this.CustomerEquipment,
            MaintenanceCustomerContract: this.CustomerContract,
            MaintenanceCustomerContractType: this.CustomerContractType,
            /** @todo Rename to have Customer prefix? (model is in customer app) */
            // requires renaming in clientsync = easy. Should also be renamed in backend = big change.
            MaintenanceEquipmentPlan: this.CustomerEquipmentPlan, 
            /** @todo Rename to have Customer prefix? (model is in customer app)  */
            // requires renaming in clientsync = easy. Should also be renamed in backend = big change.
            MaintenanceEquipmentPlanPriority: this.CustomerEquipmentPlanPriority,
            MaintenanceCustomerEquipmentPlanTask: this.CustomerEquipmentPlanTask,
            MaintenanceCustomerAddress: this.CustomerAddress,
            MaintenanceCustomerContact: this.CustomerContact,
            MaintenanceCustomerEquipmentChangeLogTransaction: this.CustomerEquipmentChangeLogTransaction,
            MaintenanceCustomerEquipmentOrderTransaction: this.CustomerEquipmentOrderTransaction,
            //#endregion

            //#region Maintenance app: Order
            MaintenanceOrder: this.Order,
            MaintenanceOrderType: this.OrderType,
            MaintenanceOrderPriority: this.OrderPriority,
            MaintenanceOrderSparePartAssignment: this.OrderSparePartAssignment,
            MaintenanceOrderServiceItemAssignment: this.OrderServiceItemAssignment,
            MaintenanceOrderStep: this.OrderStep,
            MaintenanceOrderAssignment: this.OrderAssignment,
            MaintenanceOrderSignatureImage: this.OrderSignatureImage,
            MaintenanceCompletionCode: this.CompletionCode,
            MaintenanceCompletionCodeGroup: this.CompletionCodeGroup,
            MaintenanceRejectionCode: this.RejectionCode,
            MaintenanceDebitCode: this.DebitCode,
            //#endregion
            //#region Maintenance app: Messaging
            MaintenanceTechnicianMessage: this.TechnicianMessage,
            MaintenanceTechnicianMessageRecipient: this.TechnicianMessageRecipient,
            MaintenanceTechnicianMessageConfirmation: this.TechnicianMessageConfirmation,
            //#endregion
            //#region Maintenance app: Tracking
            MaintenanceLocation: this.Location,
            MaintenanceLocationRegion: this.LocationRegion,
            MaintenanceLocationDefaultServiceItem: this.LocationDefaultServiceItem,
            //#endregion
            //#region Maintenance app: Documentation
            MaintenanceDocumentationLink: this.DocumentationLink,
            MaintenanceDocumentationFile: this.DocumentationFile,
            //#endregion
        }
    }

    constructor(name: string)
    {
        super(name);

        this.version(2).stores({
            //#region Maintenance app: General
            Equipment: 'id,ext_id,type_id',
            EquipmentSparePartAssignment: 'id,equipment_id,spare_part_id',
            EquipmentType: 'id,ext_id',
            Item: 'id,ext_id,location_id,group_id,quantity_unit_id',
            ItemQuantityUnit: 'id,ext_id',
            SubItemAssignment: 'id,parent_id,child_id',
            StepStatus: 'id,ext_id',
            Stock: 'id',
            StockItem: 'id,stock_id,item_id,type',
            //#endregion
            //#region Maintenance app: Auth
            MaintenanceAccount: 'user_id',
            //#endregion
            //#region Maintenance app: Customer
            Customer: 'id,ext_id,group_id,location_id',
            CustomerGroup: 'id,ext_id',
            CustomerEquipment: 'id,ext_id,delivery_customer_id,billing_customer_id,equipment_id,location_id',
            CustomerContract: 'id,ext_id,customer_id,equipment_id,type_id',
            CustomerContractType: 'id,ext_id',
            CustomerEquipmentPlan: 'id,ext_id,priority_id,equipment_id,order_type_id,location_id',
            CustomerEquipmentPlanPriority: 'id,ext_id',
            CustomerEquipmentPlanTask: 'id,ext_id,location_id',
            CustomerEquipmentPlanTaskStep: 'id,task_id,status_id',
            CustomerAddress: 'id,ext_id,customer_id,full_text,full_text_lower',
            CustomerContact: 'id,customer_id',
            CustomerEquipmentChangeLogTransaction: 'id,customer_equipment_id,user_account_id',
            CustomerEquipmentOrderTransaction: 'id,customer_equipment_id',
            //#endregion
            //#region Maintenance app: Order
            Order: '++id,ext_id,state,type_id,priority_id,customer_id,customer_equipment_id,plan_id,contract_id,completion_code_id,rejection_code_id,debit_code_id',
            OrderType: 'id,ext_id,sort_order',
            OrderPriority: 'id,ext_id',
            OrderSparePartAssignment: '++id,spare_part_id,order_id',
            OrderServiceItemAssignment: '++id,service_item_id,order_id',
            OrderStep: '++id,order_id,status_id',
            OrderAssignment: '++id,order_id,user_id',
            OrderSignatureImage: '++id,order_id,user_id',
            CompletionCode: 'id,ext_id',
            RejectionCode: 'id,ext_id',
            DebitCode: 'id,ext_id',
            //#endregion
            //#region Maintenance app: Messaging
            TechnicianMessage: '++id,priority_id,created_by_id,location_id,visibility_starts_at,visibility_ends_at',
            TechnicianMessageRecipient: '++id,user_id,message_id',
            TechnicianMessageConfirmation: '++id,user_id,message_id,read_at',
            //#endregion
            //#region Maintenance app: Tracking
            Location: 'id,ext_id',
            LocationRegion: 'id,location_id,postal_code_min,postal_code_max',
            LocationDefaultServiceItem: 'id,location_id,service_item_id',
            //#endregion
            //#region Maintenance app: Documentation
            DocumentationLink: '++id,object_id,sort_index,content_type_id,content_type_app_label,content_type_model,content_type_model_name',
            DocumentationFile: '++id,object_id,sort_index,content_type_id,content_type_app_label,content_type_model,content_type_model_name',
            //#endregion
        });

        // New model added
        this.version(3).stores({
            CompletionCodeGroup: 'id,ext_id'
        });
        
        // ItemGroup model added
        this.version(4).stores({
            ItemGroup: 'id,ext_id',
        });

        // ItemGroup model added
        this.version(5).stores({
            EquipmentServiceItemAssignment: 'id, equipment_id, service_item_id',
        });

        // OrderSparePartAssignment & OrderServiceItemAssignment parent assignment ids
        this.version(6).stores({
            OrderSparePartAssignment: '++id,spare_part_id,order_id,parent_service_item_assignment_id,parent_spare_part_assignment_id',
            OrderServiceItemAssignment: '++id,service_item_id,order_id,parent_service_item_assignment_id,parent_spare_part_assignment_id',
        });

        this.version(7).stores({
            EquipmentServiceItemAssignment: 'id, equipment_id, service_item',
            EquipmentSparePartAssignment: 'id, equipment_id, spare_part',
        });

        this.version(8).stores({
            Location: 'id,ext_id,parent_id',
        })
    }

    public mapTableClasses()
    {
        super.mapTableClasses();

        //#region Maintenance app: General
        this.Equipment.mapToClass(Equipment);
        this.EquipmentSparePartAssignment.mapToClass(EquipmentSparePartAssignment);
        this.EquipmentServiceItemAssignment.mapToClass(EquipmentServiceItemAssignment);
        this.EquipmentType.mapToClass(EquipmentType);
        this.ItemQuantityUnit.mapToClass(ItemQuantityUnit);
        this.ItemGroup.mapToClass(ItemGroup);
        this.Item.mapToClass(Item);
        this.StepStatus.mapToClass(StepStatus);
        this.Stock.mapToClass(Stock);
        this.StockItem.mapToClass(StockItem);
        //#endregion
        //#region Maintenance app: Auth
        this.MaintenanceAccount.mapToClass(MaintenanceAccount);
        //#endregion
        //#region Maintenance app: Customer
        this.Customer.mapToClass(Customer);
        this.CustomerGroup.mapToClass(CustomerGroup);
        this.CustomerEquipment.mapToClass(CustomerEquipment);
        this.CustomerContract.mapToClass(CustomerContract);
        this.CustomerContractType.mapToClass(CustomerContractType);
        this.CustomerEquipmentPlan.mapToClass(CustomerEquipmentPlan);
        this.CustomerEquipmentPlanPriority.mapToClass(CustomerEquipmentPlanPriority);
        this.CustomerEquipmentPlanTask.mapToClass(CustomerEquipmentPlanTask);
        this.CustomerAddress.mapToClass(CustomerAddress);
        this.CustomerContact.mapToClass(CustomerContact);
        this.CustomerEquipmentChangeLogTransaction.mapToClass(CustomerEquipmentChangeLogTransaction);
        //#endregion
        //#region Maintenance app: Order
        this.Order.mapToClass(Order);
        this.OrderType.mapToClass(OrderType);
        this.OrderPriority.mapToClass(OrderPriority);
        this.OrderSparePartAssignment.mapToClass(OrderSparePartAssignment);
        this.OrderServiceItemAssignment.mapToClass(OrderServiceItemAssignment);
        this.OrderStep.mapToClass(OrderStep);
        this.OrderAssignment.mapToClass(OrderAssignment);
        this.OrderSignatureImage.mapToClass(OrderSignatureImage);
        this.CompletionCode.mapToClass(CompletionCode);
        this.CompletionCodeGroup.mapToClass(CompletionCodeGroup);
        this.RejectionCode.mapToClass(RejectionCode);
        this.DebitCode.mapToClass(DebitCode);
        //#endregion
        //#region Maintenance app: Messaging
        this.TechnicianMessage.mapToClass(TechnicianMessage);
        this.TechnicianMessageRecipient.mapToClass(TechnicianMessageRecipient);
        this.TechnicianMessageConfirmation.mapToClass(TechnicianMessageConfirmation);
        //#endregion
        //#region Maintenance app: Tracking
        this.Location.mapToClass(Location);
        this.LocationRegion.mapToClass(LocationRegion);
        this.LocationDefaultServiceItem.mapToClass(LocationDefaultServiceItem);
        this.CustomerEquipmentOrderTransaction.mapToClass(CustomerEquipmentOrderTransaction);
        //#endregion
        //#region Maintenance app: Documentation
        this.DocumentationLink.mapToClass(DocumentationLink);
        this.DocumentationFile.mapToClass(DocumentationFile);
        //#endregion
    }

    public get userLocationAndUserLanguageBasedTables() {
        return [
            this.Order, this.OrderSparePartAssignment, this.OrderServiceItemAssignment,
            this.OrderStep, this.OrderAssignment, this.OrderSignatureImage,

            this.Item, this.ItemQuantityUnit, this.Stock, this.StockItem,
            this.SubItemAssignment, 
            this.Customer, this.CustomerEquipment, this.CustomerContract, 
            this.CustomerContact, this.CustomerEquipmentPlan, this.CustomerAddress,
            this.CustomerEquipmentChangeLogTransaction, this.CustomerEquipmentOrderTransaction,

            this.DebitCode, this.RejectionCode, this.OrderPriority,
            this.CompletionCode, this.CompletionCodeGroup,
            this.StepStatus, this.OrderType,
        ];
    }

    public async clearUserLocationAndUserLanguageBasedTables() {
        let promises: PromiseLike<void>[] = [];

        for (let table of this.userLocationAndUserLanguageBasedTables)
        {
            promises.push(table.clear());
        }

        return await Promise.all(promises);
    }

    public async clearUserLocationAndUserLanguageBasedTablesAndForceInitialSync(app: AppPlugin) {
        return this.Attribute.where('type').equals(AttributeType.LAST_SYNCHRONIZATION).delete()
        .then(() =>
        {
            return this.clearUserLocationAndUserLanguageBasedTables();
        });
    }
}